import React from 'react';
import { Link } from 'react-scroll';
import './footer.css';
import { ReactComponent as Logo } from '../../assets/gratyslogo2.svg';  // Zmieniamy na SVG

const Footer2 = () => {
  return (
    <footer>
    <Link to="about" smooth={true} duration={500} className='footer__logo'>
        <Logo className="footer-logo" />  {/* Zastosowanie komponentu Logo */}
      </Link>

      <ul className='permalinks'>
        <li><a href="/">Strona główna</a></li>
        <li><Link to="about" smooth={true} duration={500}>Informacje na temat usługi</Link></li>
        <li><Link to="contact" smooth={true} duration={500}>Kontakt</Link></li>
      </ul>
      
      <div className="footer__copyright">
        <small>&copy; Gratys.pl Wszelkie prawa zastrzeżone.</small>
      </div>
    </footer>
  );
}

export default Footer2;
