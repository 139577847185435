import React from 'react';
import { Link } from 'react-scroll';
import './footer.css';
import { ReactComponent as Logo } from '../../assets/gratyslogo2.svg';  // Zmieniamy na SVG

const Footer = () => {
  return (
    <footer>
      <a href="/" className='footer__logo'>
        <Logo className="footer-logo" />  {/* Zastosowanie komponentu Logo */}
      </a>

      <ul className='permalinks'>
        <li><a href="/">Strona główna</a></li>
        <li><Link to="about" smooth={true} duration={500}>O mnie</Link></li>
        <li><Link to="services" smooth={true} duration={500}>Usługi</Link></li>
        <li><Link to="portfolio" smooth={true} duration={500}>Portfolio</Link></li>
        <li><Link to="prices" smooth={true} duration={500}>Cennik</Link></li>
        <li><Link to="contact" smooth={true} duration={500}>Kontakt</Link></li>
      </ul>
      
      <div className="footer__copyright">
        <small>&copy; Gratys.pl Wszelkie prawa zastrzeżone.</small>
      </div>
    </footer>
  );
}

export default Footer;
