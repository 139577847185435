import React from 'react';
import './HomePage.css';
import bgTexture from '../assets/bg-texture.png';
import { ReactComponent as Logo } from '../assets/gratyslogo.svg';
import corner1 from '../assets/corner_img.png';

function HomePage() {
  return (
    <div 
      className="home" 
      style={{ backgroundImage: `url(${bgTexture})` }}
    >
      <Logo className="logo" />
      <h1>Jest mi bardzo miło, gościć Cię na mojej stronie 😉<br />Wybierz usługę, która Cię interesuje:</h1>
      <div className="buttons">
        <a href="?page=grafika" className="btn2">Grafika</a>
        <a href="?page=druk3d" className="btn2">Druk 3D</a>
      </div>
      {/* Przenosimy obrazek na poziom głównego kontenera */}
      <img src={corner1} alt="Tu powinno być zdjęcie" className="corner-image1" />
    </div>
  );
}

export default HomePage;
