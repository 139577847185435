import React from 'react';
import './about2.css';
import ME2 from '../../assets/druk3d1.mp4'; // Import pliku wideo zamiast obrazka

const About = () => {
  return (
    <section id='about'>
      <h5>Kilka informacji na temat druku</h5>
      <h2>O moich usługach</h2>

      <div className="countainer about__container">
        <div className="about__me2">
          <div className="about__me-image2">
            {/* Użyj elementu video zamiast img */}
            <video src={ME2} alt="Wideo o usługach" autoPlay loop muted></video>
          </div>
        </div>
        
        <div className="about__content">
          <p>
            Druk 3D ma zastosowanie w wielu dziedzinach, zarówno przy tworzeniu pełnoprawnego produktu jak i przy prototypowych elementach,
            które warto przed rozpoczęciem produkcji obejrzeć na żywo i przetestować. 
          </p>

          <p>
            Potrzebujesz dorobić uszkodzony element lub część, której nie da się kupić? Druk 3D będzie świetnym rozwiązaniem w większości przypadków.
            Chcesz stworzyć coś całkowicie od zera lub zrobić coś personalizowanego? Chętnie Ci w tym pomogę, drukuję z dokładnością do 0.02mm, potrafię tworzyć zarówno proste jak i bardziej skomplikowane projekty :)
            ...a może chcesz tylko wydrukować projekt, który Ci się spodobał i gdzieś go widziałeś/masz swój plik? Też chętnie pomogę, odezwij się poprzez formularz lub dane kontaktowe podane na dole strony ;)
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
