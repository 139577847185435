import React from 'react'
import './about3.css'
import ME3 from '../../assets/druk3d2.mp4'

const About3 = () => {
  return (
    <section id='about3'>
    <div className="container about__container">
      {/* Tekst po lewej stronie */}
      <div className="about__content3">
        <p>
          <b>Z jakich materiałów korzystam?</b>
          <br></br>
          <b>PLA</b> - Jest to polimer uzyskiwany z surowców roślinnych dlatego nie ma negatywnego wpływu na środowisko. Jest biodegradowalny. Można z niego wykonać zabawki dla dzieci, prototypy lub gotowe produkty, które nie będą wystawione na działanie promieni słonecznych czy też wysokich temperatur.
        <br></br>
        <b>PET-G</b> - Połączenie najlepszych cech PET (używanego m.in. do produkcji butelek) z dodatkiem glikolu, co zwiększa jego elastyczność i odporność na uszkodzenia. Umożlwia wykonanie trwałych, wytrzymałych przedmiotów, które są jednocześnie elastyczne i odporne na uderzenia. Idealnie nadaje się do produkcji elementów wykorzystywanych w elektronice, części maszyn, a także przedmiotów codziennego użytku, które wymagają większej wytrzymałości.
       <br></br>
       <b>ABS</b> - Bardzo wytrzymały materiał, który jest idealny do produkcji elementów, które są narażone na duże obciążenia mechaniczne. Ma też dużą odporność na ścieralność.
       <br></br>
       <b>TPU</b> - Jest elastyczny, ma wysoką wytrzymałość na rozdarcie i oferuje dobre szczegóły. Odporny na uszkodzenia mechaniczne, takie jak zarysowania, uderzenia, czy ścieranie.
       <br></br>
       ...i wiele innych takich jak np. nylon, po więcej szczegółów lub żeby uzyskać poradę jaki materiał będzie najlepszy do Twojego projektu zachęcam do kontaktu :)
        </p>
      </div>

      {/* Obrazek po prawej stronie */}
      <div className="about__me3">
        <div className="about__me-image3">
          <video src={ME3} alt="Wideo o usługach" autoPlay loop muted></video>
        </div>
      </div>
    </div>
  </section>
  )
}

export default About3