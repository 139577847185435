import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import HomePage from './components/HomePage';
import GrafikaPage from './components/GrafikaPage';
import Druk3DPage from './components/Druk3DPage';
import Errorpage from './components/Errorpage';

function App() {
  // Pobieramy parametr "page" z adresu URL
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');

  // Renderowanie odpowiedniej strony w zależności od wartości parametru "page"
  switch (page) {
    case 'grafika':
      return <GrafikaPage />;
    case 'druk3d':
      return <Druk3DPage />;
    case null:
      return <HomePage />;
    default:
      return <Errorpage />;
  }
}

export default function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}