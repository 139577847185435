import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href="https://www.instagram.com/gratysdesign/" target='_blank' rel='noreferrer'><BsInstagram /></a>
      <a href="https://www.tiktok.com/@gratys.design" target='_blank' rel='noreferrer'><FaTiktok /></a>
    </div>
  );
};

export default HeaderSocials;
