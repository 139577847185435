import React from 'react';
import './Errorpage.css';
import bgTexture from '../assets/bg-texture.png';
import { ReactComponent as Logo } from '../assets/gratyslogo.svg';

function Errorpage() {
  return (
    <div 
      className="error" 
      style={{ backgroundImage: `url(${bgTexture})` }}
    >
      <Logo className="logo" />
      <h1>Niestety wystąpił błąd przy ładowaniu strony😢<br />Spróbuj ponownie później lub upewnij się, że taka strona istnieje</h1>
      </div>
  );
}

export default Errorpage;
