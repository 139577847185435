import React from 'react';
import { Link } from 'react-scroll';
import './nav.css';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { AiOutlineMessage } from 'react-icons/ai';

const Nav = () => {
  return (
    <nav>
      <Link
        to="about"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <IoIosInformationCircleOutline />
      </Link>
      <Link
        to="contact"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <AiOutlineMessage />
      </Link>
    </nav>
  );
};

export default Nav;