import React from 'react';
import { Element } from 'react-scroll';
import Nav from '../components/nav/Nav';
import Header from '../components/header/Header';
import About from '../components/about/About';
import Services from '../components/services/Services';
import Portfolio from '../components/portfolio/Portfolio';
import Prices from '../components/prices/prices';
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';

function GrafikaPage() {
  return (
    <div className="grafika-page">
      <Nav />
      <Element name="home">
        <Header />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="services">
        <Services />
      </Element>
      <Element name="portfolio">
        <Portfolio />
      </Element>
      <Element name="prices">
        <Prices />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Footer />
    </div>
  );
}

export default GrafikaPage;
