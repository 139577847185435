import React from 'react';
import './about4.css';
import ME4 from '../../assets/druk3d3.mp4';

const About4 = () => {
  return (
    <section id='about4'>
      <a href='#contact' className='scroll__down2'>Przewiń w dół</a>
      <div className="countainer about__container">
        <div className="about__me4">
          <div className="about__me-image4">
            <video src={ME4} alt="Wideo o usługach" autoPlay loop muted></video>
          </div>
        </div>
        
        <div className="about__content4">
          <p>
            <b>Czy druk 3D jest drogi?</b>
            <br></br>W przypadku usługi druku nie jest możliwe określenie stałej stawki - każdy projekt wymaga indywidualnej wyceny.
            <br></br>W związku z tym jeśli masz pytania o cenę, napisz do mnie wiadomość z prośbą o wycenę, załącz projekt jeśli go posiadasz, a ja wycenię go w ciągu 24 godzin za darmo. Staram się jednak, aby usługa była korzystna cenowo :)
            <br></br><b>Ważne, aby w takiej wiadomości zawrzeć kluczowe informacje takie jak:</b>
            <br></br>1. Zastosowanie drukowanego przedmiotu (Informacja pomoże określić jaki materiał będzie najlepszy)
            <br></br>2. Preferowany kolor lub kolory wydruku - w celu uzyskania informacji jakie są dostępne w danym momencie należy skontaktować się poprzez formularz lub dane kontaktowe.
            <br></br>3. Ilość sztuk
            <br></br>4. Wymiary podane w milimetrach
            <br></br>5. Możliwie najdokładniejszy opis tego co należy stworzyć w ramach zlecenia lub opcjonalnie przesłanie pliku do druku (format stl, blend etc.)
          </p>     
        </div>
      </div>
      <div className="about__buttons">
        <a href="https://allegro.pl/uzytkownik/DailyDoseOfFilip" className="btn2" target="_blank" rel="noreferrer">Moje gotowe projekty na Allegro</a>
        <a href="https://www.olx.pl/oferty/uzytkownik/gyINH/" className="btn2" target="_blank" rel="noreferrer">Moje gotowe projekty na OLX</a>
      </div>
    </section>
  );
};

export default About4;
