import React from 'react';
import { Element } from 'react-scroll';
import Nav from '../components/nav2/Nav';
import About from '../components/about2/About';
import About2 from '../components/about3/About3';
import About4 from '../components/about4/About4';
import Contact from '../components/contact/Contact';
import Footer2 from '../components/footer2/Footer';

function Druk3DPage() {
  return (
    <div className="druk3d-page">
      <Nav />
      <Element name="about">
        <About />
      </Element>
      <Element name="about2">
        <About2 />
      </Element>
      <Element name="about4">
        <About4 />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Footer2 />
    </div>
  );
}

export default Druk3DPage;
